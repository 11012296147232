import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppRoutePath, AppRoutePathParam } from '@falcon/app/app.routing.model';
import { authorizedGuard, unauthorizedGuard } from '@falcon/app/core/api/auth';

const ROUTES: Routes = [
  {
    path: AppRoutePath.ROOT,
    pathMatch: 'full',
    redirectTo: AppRoutePath.SEARCH,
  },
  {
    path: AppRoutePath.SEARCH,
    loadChildren: () => import('./modules/search').then(m => m.SearchModule),
    canMatch: [unauthorizedGuard],
  },
  {
    path: `${AppRoutePath.COMPANY}/:${AppRoutePathParam.COMPANY_ID}`,
    loadChildren: () => import('./modules/company').then(m => m.CompanyModule),
    canMatch: [unauthorizedGuard],
  },
  {
    path: `${AppRoutePath.EMPLOYEE}/:${AppRoutePathParam.EMPLOYEE_ID}`,
    loadChildren: () => import('./modules/employee').then(m => m.EmployeeModule),
    canMatch: [unauthorizedGuard],
  },
  {
    path: AppRoutePath.CONTRACT,
    loadChildren: () => import('./modules/contract').then(m => m.ContractModule),
    canMatch: [unauthorizedGuard],
  },
  {
    path: AppRoutePath.CREATE_EMPLOYEE,
    loadChildren: () => import('./modules/create-employee').then(m => m.CreateEmployeeModule),
    canMatch: [unauthorizedGuard],
  },
  {
    path: AppRoutePath.INVITATIONS,
    loadChildren: () => import('./modules/invitations').then(m => m.InvitationsModule),
  },
  {
    path: `${AppRoutePath.INVITE_EMPLOYEE_REGISTRATION}/:${AppRoutePathParam.INVITATION_ID}`,
    loadChildren: () =>
      import('./modules/invite-employee-registration').then(
        m => m.InviteEmployeeRegistrationModule
      ),
  },
  {
    path: AppRoutePath.AUTH,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canMatch: [authorizedGuard],
  },
  {
    path: AppRoutePath.EMPLOYEE_REGISTRATION,
    loadChildren: () =>
      import('./modules/employee-registration').then(m => m.EmployeeRegistrationModule),
  },
  {
    path: AppRoutePath.UPDATE_REQUIRED,
    loadComponent: () => import('./modules/update-required').then(c => c.UpdateRequiredComponent),
  },
  {
    path: AppRoutePath.EMPLOYEE_USER,
    loadChildren: () => import('./modules/employee-user').then(m => m.EmployeeUserModule),
    canMatch: [unauthorizedGuard],
  },
  {
    path: AppRoutePath.FLASH,
    loadChildren: () => import('./modules/flash').then(m => m.FlashModule),
    canMatch: [unauthorizedGuard],
  },
  {
    // Helper component for authentication to capture skey query param after BE redirect
    path: 'signin',
    loadComponent: () => import('./modules/signin/signin.component').then(c => c.SigninComponent),
  },
  {
    // BE redirects to this page when login/registration with ItsMe error occurs
    path: 'login-error',
    loadComponent: () =>
      import('./modules/login-error/login-error.component').then(c => c.LoginErrorComponent),
  },
  {
    path: '**',
    redirectTo: AppRoutePath.SEARCH,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
