import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { environment } from '@falcon/env';
import { AuthStore } from '@falcon/app/core/storage';
import { WT_SUPPORT_PHONE_NUMBER } from '@falcon/app/shared/constants';
import {
  AppUpdateService,
  GACustomEventName,
  GoogleAnalyticsService,
} from '@falcon/app/shared/services';
import { AppRoutePath } from '@falcon/app/app.routing.model';

@UntilDestroy()
@Component({
  selector: 'falcon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
  readonly isOldDomainUsed = window.location.href.includes('my.digitalpayrollservices.be');
  readonly supportPhoneNumber = PhoneNumberUtil.getInstance().format(
    WT_SUPPORT_PHONE_NUMBER,
    PhoneNumberFormat.NATIONAL
  );
  private readonly appVersion = environment.appVersion;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private authStore: AuthStore,
    private gaService: GoogleAnalyticsService,
    private router: Router,
    private zone: NgZone,
    private appUpdateService: AppUpdateService,
    private platform: Platform
  ) {
    this.initDeepLinkListener();
    this.initGTM(environment.gtmId);
    this.renderer.setAttribute(this.document.documentElement, 'app-version', this.appVersion);
    this.renderer.setAttribute(this.document.documentElement, 'app-env', environment.name);
  }

  ngOnInit() {
    this.appUpdateService.initUpdates();

    this.authStore
      .getUserData$()
      .pipe(untilDestroyed(this))
      .subscribe(currentUser => {
        const appPlatform: string = this.platform.is('pwa') ? 'pwa' : Capacitor.getPlatform();
        this.gaService.fireEvent(GACustomEventName.APP_OPENED, {
          currentUser,
          appVersion: this.appVersion,
          appPlatform: appPlatform,
        });

        if (this.authStore.isEmployeeUser()) {
          this.router.navigateByUrl(
            currentUser.isDraft ? AppRoutePath.EMPLOYEE_REGISTRATION : AppRoutePath.EMPLOYEE_USER
          );
        }
        if (this.authStore.isCompanyUser()) {
          this.router.navigateByUrl(`${AppRoutePath.COMPANY}/${currentUser.managedCompanyId}`);
        }
      });

    console.log('App platform: ', Capacitor.getPlatform());
  }

  ngAfterViewInit(): void {
    SplashScreen.hide();
  }

  private initGTM(id: string): void {
    const gtmScript: HTMLScriptElement = this.renderer.createElement('script');
    gtmScript.type = 'text/javascript';
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`;
    this.renderer.insertBefore(this.document.head, gtmScript, this.document.head.children[0]);

    const gtmNoScript: HTMLElement = this.renderer.createElement('noscript');
    const gtmNoScriptIframe: HTMLIFrameElement = this.renderer.createElement('iframe');
    gtmNoScriptIframe.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${id}`);
    gtmNoScriptIframe.setAttribute('height', '0');
    gtmNoScriptIframe.setAttribute('width', '0');
    gtmNoScriptIframe.setAttribute('style', 'display:none;visibility:hidden');
    gtmNoScript.appendChild(gtmNoScriptIframe);
    this.renderer.insertBefore(this.document.body, gtmNoScript, this.document.body.children[0]);
  }

  private initDeepLinkListener(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        console.log('appUrlOpen', event);
        const path = event.url.split(environment.baseUrl.replace('https://', '')).pop();
        if (path) {
          this.router.navigateByUrl(path);
        }
      });
    });
  }
}
